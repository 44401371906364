const isDevEnv = process.env.NODE_ENV === "development";
export function addRecaptchaScript() {
  // return Promise.reject("unspecified");
  if (isDevEnv) return Promise.resolve();

  const scriptId = "ce131de4879746ff9e471d5126dd3005";
  if (document.getElementById(scriptId)) return Promise.resolve();

  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");
      script.addEventListener("load", resolve);
      script.addEventListener("error", () => {
        reject("unspecified");
      });
      script.id = scriptId;
      script.src =
        "https://www.google.com/recaptcha/api.js?render=" +
        process.env.NEXT_PUBLIC_RECAPTCHASITEKEY;

      document.body.appendChild(script);
    } catch {
      reject("unspecified");
    }
  });
}

export async function getRecaptchaToken(action) {
  return new Promise(function (resolve, reject) {
    if (isDevEnv) return resolve("abc");
    try {
      if (!window.grecaptcha) {
        reject("unspecified");
        return;
      }

      window.grecaptcha.ready(() => {
        try {
          window.grecaptcha
            .execute(process.env.NEXT_PUBLIC_RECAPTCHASITEKEY, { action })
            .then(resolve)
            .catch(() => reject("unspecified"));
        } catch (err) {
          // c1o2n3sole.log(err)
          reject("unspecified");
        }
      });
    } catch (err) {
      reject("unspecified");
    }
  });
}
