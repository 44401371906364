import MetotHiOutlineExclamation from "./icon-center/MetotHiOutlineExclamation";

export default function PageErrorMessage() {
  return (
    <div className="max-w-70 mx-auto mt-2 p-5 px-10">
      <div className="flex items-center gap-3 text-xl font-bold text-red-600">
        <MetotHiOutlineExclamation
          size={"1.8rem"}
          className={"text-orange-600"}
        />
        <div>Hata oluştu</div>
      </div>
      <div className="text-center">
        <button
          className="mt-6 rounded border bg-slate-200 p-1 px-4 text-xs"
          onClick={() => window.location.reload()}
        >
          Yeniden Dene
        </button>
      </div>
    </div>
  );
}
