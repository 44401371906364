"use client";
import { addRecaptchaScript } from "@/components/grecaptcha";
import MetotHiOutlineExclamation from "@/components/icon-center/MetotHiOutlineExclamation";
import Input from "@/components/input/input";
import InputGroup from "@/components/input/input-group";
import Loading from "@/components/loading";
import PageErrorMessage from "@/components/page-error-message";
import SlimLoading from "@/components/slim-loading/slim-loading";
import { shake } from "@/components/utils";
import useLoginUnit from "@/modules/auth/use-login-unit";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import useAppInfo from "../app/use-app-info";

export default function LoginUnit() {
  const changeLoginForm = useLoginUnit((state) => state.changeLoginForm);
  const form = useLoginUnit((state) => state.loginForm);
  const errors = useLoginUnit((state) => state.errors);
  const validate = useLoginUnit((state) => state.validate);
  const login = useLoginUnit((state) => state.login);
  const pageStatus = useLoginUnit((state) => state.pageStatus);
  const setPageStatus = useLoginUnit((state) => state.setPageStatus);
  const isLoggedIn = useAppInfo((state) => state.userToken);

  const router = useRouter();

  useEffect(() => {
    if (isLoggedIn) {
      router.replace("/uygulamalarim");
      return;
    }
    setPageStatus("loading");
    addRecaptchaScript()
      .then(() => setPageStatus("ready"))
      .catch(() => setPageStatus("recaptcha-script-load-error"));
  }, [router, setPageStatus, isLoggedIn]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate() !== true) {
      shake();
      return;
    }

    const success = await login();
    if (success) window.location.replace("/uygulamalarim");
  }

  switch (pageStatus) {
    case "loading":
      return <Loading />;
    case "recaptcha-script-load-error":
      return <PageErrorMessage />;
    default:
      return (
        <>
          <SlimLoading status={pageStatus} />
          <form
            onSubmit={handleSubmit}
            className="w-full max-w-sm rounded-lg bg-white p-5 shadow-lg sm:p-10"
          >
            <ErrorMessageViewer pageStatus={pageStatus} />

            <fieldset
              disabled={pageStatus === "running"}
              className="flex flex-col gap-4 disabled:opacity-40"
            >
              <h1 className="my-3 text-center text-2xl font-medium">
                Giriş Yap
              </h1>

              <InputGroup errorMessage={errors.eMail}>
                <label className="mb-2 block text-gray-600">
                  E-Posta Adresiniz :
                </label>
                <Input
                  type="text"
                  value={form.eMail}
                  onChange={(eMail) => changeLoginForm({ eMail })}
                  className={"w-full py-2"}
                />
              </InputGroup>

              <InputGroup errorMessage={errors.password}>
                <label className="mb-2 block text-gray-600">Şifreniz :</label>
                <Input
                  type="password"
                  value={form.password}
                  onChange={(password) => changeLoginForm({ password })}
                  className={"w-full py-2"}
                />
              </InputGroup>

              <button
                id="shakingButton"
                type="submit"
                className="block rounded-sm bg-slate-700 p-3 text-center text-white duration-300 hover:bg-slate-950"
              >
                Giriş Yap
              </button>
            </fieldset>
          </form>
        </>
      );
  }
}

function ErrorMessageViewer({ pageStatus }) {
  switch (pageStatus) {
    case "conflict":
    case "notfound":
      return (
        <div className="flex items-center justify-center rounded bg-red-200 p-3 text-center">
          <MetotHiOutlineExclamation className="me-2" />
          <span className="text-sm">Giriş bilgileri doğrulanamadı.</span>
        </div>
      );
    case "unspecified":
      return (
        <div className="flex items-center justify-center rounded bg-red-200 p-3 text-center">
          <MetotHiOutlineExclamation className="me-2" />
          <span className="text-sm">Hata oluştu.</span>
        </div>
      );
    case "temporarily-locked":
      return (
        <div className="flex items-center justify-center rounded bg-orange-400 p-3 text-center">
          <MetotHiOutlineExclamation className="me-2" />
          <span className="text-sm">Hesabınız geçici olarak bloke edildi.</span>
        </div>
      );
  }
}
